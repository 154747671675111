@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  margin: 0;
  font-family: 'Kanit', sans-serif;
  background-color: #0D1117;
}

.meter {
  box-sizing: content-box;
  height: 30px;
  width: 90%;
  position: relative;
  margin-top: 5px;
  margin-bottom: 10px;
  background: #b6b5b557;
  border-radius: 25px;
  padding-left: 5px;
  padding-top: 4px;
  padding-bottom: 5px;
  padding-right: 5px;
  box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
}

.meter>span {
  display: block;
  height: 100%;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: rgb(2, 82, 255);
  background-image: linear-gradient(center bottom,
      rgb(29, 88, 216) 37%,
      rgb(18, 93, 255) 69%);
  box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3),
    inset 0 -2px 6px rgba(0, 0, 0, 0.4);
  position: relative;
  overflow: hidden;
}

.meter>span:after,
.animate>span>span {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-image: linear-gradient(-45deg,
      rgba(0, 0, 0, 0.123) 25%,
      transparent 25%,
      transparent 50%,
      rgba(0, 0, 0, 0.123) 50%,
      rgba(0, 0, 0, 0.123) 75%,
      transparent 75%,
      transparent);
  z-index: 1;
  background-size: 50px 50px;
  animation: move 5s linear infinite;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  overflow: hidden;
}

.animate>span:after {
  display: none;
}

@keyframes move {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 50px 50px;
  }
}

.fixed-head{
  position: fixed;
  top: 0;
  left: 0;
}
.fixed-head-two{
  position: fixed;
  top: 50px;
  left: 0;
}